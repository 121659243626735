#desktop-menu {
    display: flex;
    align-items: center;
    color: white;
    font: 700 1.05rem Kalam, sans-serif;
}

.desktop-menu-item {
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: fit-content;
    width: fit-content;
    color: white;
    cursor: pointer;
    border-bottom: rgba(68, 67, 67, 0.52) 2px solid;
    padding-bottom: 5px;
}

.desktop-menu-item-selected {
    border-bottom-color: yellow;
}

#contact-me-header {
    color: yellow;
}

#contact-me-header:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

#contact-me-header:not(:hover) {
    transition: transform 0.2s ease-in-out;
}


@media only screen and (max-width: 1024px) {
    #desktop-menu {
        font-size: 0.9rem;
        padding-inline-start: 0;
    }

    .desktop-menu-item {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 768px) {
    #desktop-menu {
        display: none;
    }
}