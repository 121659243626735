#mobile-menu {
    display: none;
}

#mobile-menu-button {
    color: white;
}

#mobile-menu-icon {
    font-size: 30px;
}

#mobile-contact-me-header {
    color: yellow;
}


@media only screen and (max-width: 768px) {
    #mobile-menu {
        display: inline;
    }
}