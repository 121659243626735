#contact-me-body {
    display: grid;
    grid-template-columns: 55% 40%;
    column-gap: 70px;
}


#contact-me-text {
    font: 400 calc(17px + 0.7vw) Kalam, sans-serif;
    margin-left: 10%;
    text-align: justify;
}

#contact-me-text > p {
    margin-block-start: 0;
}

#contact-me-text:not(#contact-me-last-text) {
    text-indent: 20px;
}

#contact-me-last-text {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    color: yellow;
}

.file-download {
    font-weight: 700;
    color: yellow;
    text-decoration: underline;
    cursor: pointer;
}

.file-download:hover {
    text-decoration: none;
    color: #6180fb;
}

#social-media-container {
}

.social-media-title {
    font-size: 2rem;
    padding-top: 0;
    margin-block-start: 0;
    color: yellow;
    padding-left: 0;
}

#social-media-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    text-align: start;
    list-style-position: inside;
    padding-inline-start: 0;
    margin-right: 20px;
}

.social-media-item {
    color: #6180fb;
    font-size: 1.8rem;
    margin-bottom: 30px;
    padding-left: 20px;
}

.social-media-item:nth-child(even) {
    padding-left: 10px;
}

.social-media-name {
    font: 400 calc(15px + 0.8vw) Kalam, sans-serif;
    color: gold;
}

.social-media-link {
    text-decoration: underline;
    color: white;
}

.social-media-link:hover {
    text-decoration: none;
    color: #6180fb;
}

.social-media-text {
    font: 400 calc(14px + 0.4vw) Kalam, sans-serif;
    margin-left: 15px;
    word-wrap: break-word;
}


@media only screen and (max-width: 1200px) {
    #contact-me-body {
        column-gap: 40px;
    }
}

@media only screen and (max-width: 768px) {
    #contact-me-body {
        grid-template-columns: 1fr;
    }

    #contact-me-text {
        font-size: calc(15px + 0.6vw);
        margin-left: 5%;
        margin-right: 30px;
    }

    #contact-me-last-text {
        font-size: 1.4rem;
    }

    #social-media-container {
        margin-left: 5%;
        margin-top: 20px;
    }

    .social-media-title {
        margin-block-end: 0;
    }

    #social-media-list {
        max-width: 90%;
    }

    .social-media-item {
        margin-bottom: 20px;
        padding-left: 10px;
    }

    .social-media-item:nth-child(even) {
        padding-left: 0;
    }
}

@media only screen and (max-width: 480px) {
    #social-media-list {
        grid-template-columns: 1fr;
    }

    .social-media-item {
        padding-left: 0;
    }

    .social-media-name {
        font: 400 1.4rem Kalam, sans-serif;
    }

    .social-media-text {
        font: 400 1.2rem Kalam, sans-serif;
    }
}