.tile-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-left: 5%;
    text-decoration: none;
    color: white;
}

.tile-container:hover .tile-image {
    transform: scale(1.1);
    transform-origin: 100% 50%;
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
}

.tile-container:not(:hover) .tile-image {
    transform: scale(1);
    transform-origin: 100% 50%;
    transition: transform 0.5s, opacity 0.5s;
}

.tile-container-except-image {
    display: flex;
    align-items: start;
    text-align: start;
    padding-right: 80px;
}

.tile-image-link {
    max-width: 40%;
}

.tile-image {
    width: 100%;
    border-radius: 20px;
    opacity: 0.3;
}


@media only screen and (max-width: 1200px) {
    .tile-container {
        flex-direction: column;
    }

    .tile-container:hover .tile-image {
        transform: none;
    }

    .tile-container-except-image {
        padding-right: 40px;
    }

    .tile-image-link {
        max-width: 80%;
        width: auto;
        max-height: 400px;
        margin-top: 40px;
    }

    .tile-image {
        object-fit: cover;
        max-width: 100%;
        width: auto;
        max-height: 400px;
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .tile-container-except-image {
        flex-direction: column;
    }
}