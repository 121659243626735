.filter-container {
    display: flex;
    gap: 15px;
    padding: 0 5% 20px;
    margin-bottom: 20px;
    overflow: scroll;
}

.filter-button {
    font: bold calc(16px + 0.2vw) Kalam, sans-serif;
    background: black;
    color: white;
    border: 2px solid #6180fb;
    border-radius: 25px;
    padding: 5px 15px 2px;
    text-transform: capitalize;
    cursor: pointer;
}

.selected-filter {
    background: yellow;
    color: black;
    border-color: yellow;
}

@media only screen and (max-width: 480px) {
    .filter-container {
        gap: 10px;
        margin-bottom: 10px;
    }

    .filter-button {
        padding: 8px 10px 5px;
        border-radius: 20px;
    }
}