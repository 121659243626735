.medias-tile-container {
    margin-top: 30px;
    text-align: start;
}

.medias-tile-title {
    font: 700 1.5rem Kalam, sans-serif;
}

.medias-tile {
    margin-block-start: 0;
    margin-block-end: 0;
}

.current-media {
    color: #6180fb;
}

.current-media:not(:first-child) {
    margin-top: 5px;
}

.media-tile-title {
    font: 700 1.3rem Kalam, sans-serif;
    color: gold;
}

.media-tile-title:hover {
    opacity: 0.7;
}

.media-tile-title:visited {
    color: darkgoldenrod;
}

.media-tile-description {
    margin-top: 2px;
    text-indent: 15px;
    word-spacing: 1px;
    color: white;
}