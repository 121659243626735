#about-me {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
}

#about-me-text {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    font: 300 1.4rem Kalam, sans-serif;
    max-width: 60%;

    text-align: justify;
}

.about-me-paragraph {
    margin-block: 0;
}

.about-me-link {
    text-decoration: underline;
    color: inherit;
}

.about-me-link:hover {
    color: #b48888;
}

.about-me-link:visited {
    color: rgba(114, 130, 159, 0.82);
}

.about-me-list {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.about-me-list-item::marker {
    color: yellow;
    font-size: 1.5rem;
}

#title {
    font: 700 2.3rem Kalam, sans-serif;
}

.bold {
    display: contents;
    font-weight: 700;
}

#profile-image {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    margin-right: 5%;
    box-shadow: 0 0 20px 2px rgb(208, 228, 255);
    position: sticky;
    top: 180px;
}

@media only screen and (max-width: 768px) {
    #about-me {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    #title {
        text-align: center;
    }

    #about-me-text {
        margin: 0;
        max-width: 80%;
        text-align: start;
    }

    #profile-image {
        width: 50%;
        height: 50%;
        margin: 0;
        position: relative;
        top: 0;
    }
}