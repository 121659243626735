.tile-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 30px;
    width: 100%;
    text-align: start;
}

.tile-title {
    font: 700 2rem Kalam, sans-serif;
    color: gold;
}

.tile-title-link {
    font: 700 2rem Kalam, sans-serif;
    color: gold;
    text-decoration: underline;
}

.tile-title-link:hover {
    opacity: 0.7;
}

.tile-title-link:visited {
    color: darkgoldenrod;
}

.tile-subtitle {
    font: 700 1.3rem Kalam, sans-serif;
    margin-top: -2px;
}

.tile-date {
    font: 700 1.1rem Kalam, sans-serif;
    margin-top: -5px;
    text-indent: 10px;
    color: #6180fb;
}

.tile-under-date {
    font: 400 1.2rem Kalam, sans-serif;
    margin-top: -5px;
    text-indent: 10px;
    color: #6180fb;
}

.tile-description {
    margin-top: 20px;
    margin-bottom: 30px;
    text-indent: 20px;
    word-spacing: 1px;
    width: 100%;
}

.tile-skills-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.tile-skills-title {
    font: 700 1.5rem Kalam, sans-serif;
}

.tile-skills {
    width: 100%;
    font: 400 1.2rem Kalam, sans-serif;
    text-align: start;
}


@media only screen and (max-width: 768px) {
    .tile-info {
        margin-left: 0;
    }

    .tile-title {
        font-size: 1.5rem;
    }

    .tile-subtitle {
        font-size: 1.2rem;
    }

    .tile-date {
        margin-top: 0;
    }

    .tile-skills-title {
        font-size: 1.4rem;
    }

    .tile-skills {
        font-size: 1rem !important;
    }
}