.custom-show-more-text {
    text-align: justify;
}

.show-more-text {
    font: 400 1.2rem Kalam, sans-serif;
    white-space: pre-wrap;
    display: inline;
}

.show-more-text-headline {
    font: 700 1.2rem Kalam, sans-serif;
    color: yellow;
    text-align: start;
}

.show-more-less {
    font: 300 1.1rem Kalam, sans-serif;
    color: #6180fb;
    cursor: pointer;
    word-spacing: 0;
    display: inline;
    text-decoration: underline;
    padding-left: 10px;
}

.show-more-less:hover {
    text-decoration: none;
}

.custom-bold {
    text-decoration: underline;
}

.custom-link {
    text-decoration: underline;
    font-weight: 700;
    color: #3f66fd;
}

.custom-link:hover {
    color: #3f48fd;
}

.custom-link:visited {
    color: rgba(114, 130, 159, 0.82);
}

span {
    display: inline;
}