#volunteering-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
}

#volunteering-container > div:nth-child(odd) {
    border-right: 2px solid #444;
}

#volunteering-container > div {
    border-bottom: 2px solid #444;
}

#volunteering-container > div:last-child {
    border-bottom: none;
}

#volunteering-container > div:nth-last-child(2):nth-child(odd) {
    border-bottom: none;
}

.volunteering-medias {
    margin-top: -10px;
    margin-bottom: 30px;
}

.volunteering-media-description {
    font-size: 1.1rem;
}


@media only screen and (max-width: 1200px) {
    #volunteering-container {
        grid-template-columns: 1fr;
    }

    #volunteering-container > div:nth-child(odd) {
        border-right: none;
    }

    #volunteering-container > div:nth-last-child(2):nth-child(odd) {
        border-bottom: 2px solid #444;
    }
}