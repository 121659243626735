#next-prev-button {
    font: 700 1.5rem Kalam;
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

#prev-button, #next-button {
    display: flex;
    align-items: center;
    width: 30%;
    padding-top: 10px;
    border-top: 2px solid gold;
}

#prev-button-container, #next-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#prev-button-container:hover, #next-button-container:hover {
    color: #349ce3;
    text-decoration: underline;
}

#prev-button {
    padding-right: 1rem;
    justify-content: end;
}

#prev-button-text {
    margin-left: 0.5rem;
    width: fit-content;
}

#next-button {
    padding-left: 1rem;
}

#next-button-text {
    margin-right: 0.5rem;
}


@media only screen and (max-width: 1200px) {
    #next-prev-button {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 768px) {
    #next-prev-button {
        font-size: 1.3rem;
    }

    #prev-button, #next-button {
        width: 40%;
    }
}

@media only screen and (max-width: 480px) {
    #next-prev-button {
        font-size: 1.1rem;
    }
}