#honors-and-certificates {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Kalam, sans-serif;
    text-align: start;
}

@media only screen and (max-width: 768px) {
    #honors-and-certificates-container {
        margin-left: 2%;
    }
}