#courses-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 5%;
}

.course {
    width: 90%;
    text-align: start;
    color: #6180fb;
    font-size: 1.5rem;
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-bottom: 2px solid #444;
}

.course-title {
    font: 700 1.8rem Kalam, sans-serif;
    color: gold;
    margin-bottom: 5px;
}

.course-title-link {
    text-decoration: underline;
}

.course-title-link:hover {
    color: #6180fb;
}

.course-title-link:visited {
    color: darkgoldenrod;
}

.course-number {
    font: 400 1.4rem Kalam, sans-serif;
    color: white;
}

.course-grade {
    font: 400 1.4rem Kalam, sans-serif;
    color: white;
    margin-top: -5px;
}

.course-instructor {
    font: 700 1.2rem Kalam, sans-serif;
    color: yellow;
    margin-top: 10px;
    text-indent: 15px;
}

.course-instructor-link {
    text-decoration: underline;
    color: #6180fb;
}

.course-instructor-link:hover {
    color: yellow;
}

.course-instructor-link:visited {
    color: darkgoldenrod;
}

.course-associated-with {
    font: 400 1.3rem Kalam, sans-serif;
    margin-top: -3px;
}


@media only screen and (max-width: 1024px) {
    #courses-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    #courses-list {
        grid-template-columns: 1fr;
    }

    .course {
        padding-bottom: 20px;
        margin-bottom: 15px;
    }

    .course:last-of-type {
        border-bottom: none;
    }

    .course-title {
        font-size: 1.6rem;
    }

    .course-number {
        font-size: 1.2rem;
    }

    .course-grade {
        font-size: 1.2rem;
    }

    .course-associated-with {
        font-size: 1.1rem;
        margin-top: 0;
    }
}