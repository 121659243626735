#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px 15px 30px;

    background: rgba(122, 122, 122, 0.13);
    backdrop-filter: blur(8px);

    position: sticky;
    top: 0;
    z-index: 1000;

    border-bottom: 0.01rem solid rgba(224, 224, 224, 0.42);
}

#header-left {
    display: flex;
    width: fit-content;
    align-items: center;
}

#header-logo {
    height: 50px;
    width: 50px;
}

#name-slogan {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 10px;
    height: fit-content;
    width: fit-content;
}

#header-name {
    font: 700 1.1rem Kalam, cursive;
}

#header-slogan {
    font: 500 0.9rem Merienda, cursive;
}


@media only screen and (max-width: 1024px) {
    #header {
        padding-left: 10px;
        padding-right: 20px;
    }

    #header-name {
        font-size: 1rem;
    }

    #header-slogan {
        font-size: 0.8rem;
    }
}