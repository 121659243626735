#projects {
    display: flex;
    flex-direction: column;
    font-family: Kalam, sans-serif;
}

#projects-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
}

#projects-container > div:nth-child(odd) {
    border-right: 2px solid #444;
}

#projects-container > div {
    border-bottom: 2px solid #444;
}

#projects-container > div:last-child {
    border-bottom: none;
}

#projects-container > div:nth-last-child(2):nth-child(odd) {
    border-bottom: none;
}

.project {
    display: flex;
    align-items: start;
    padding: 30px 50px 30px 5%;
}

.project-logo {
    width: 200px;
    height: auto;
    border-radius: 10px;
    margin-top: 10px;
}


@media only screen and (max-width: 1200px) {
    #projects-container {
        grid-template-columns: 1fr;
        padding: 0;
    }

    #projects-container > div:nth-child(odd) {
        border-right: none;
    }

    #projects-container > div:nth-last-child(2):nth-child(odd) {
        border-bottom: 2px solid #444;
    }

    .project {
        padding-left: 3%;
        margin-left: 2%;

        padding-right: 3%;
        margin-right: 2%;
    }
}

@media only screen and (max-width: 768px) {
    .project {
        flex-direction: column;
    }

    .project:first-of-type {
        padding-top: 0;
    }
}