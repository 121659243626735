.tile-logo-link {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
}

.tile-logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 40px;
}

.tile-logo:hover {
    opacity: 0.5;
    transform: scale(1.1);
    transition: transform 0.5s, opacity 0.5s;
}

.tile-logo:not(:hover) {
    transform: scale(1);
    transition: transform 0.5s, opacity 0.5s;
}


@media only screen and (max-width: 768px) {
    .tile-logo-link {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }

    .tile-logo {
        width: 100px;
        height: 100px;
    }
}